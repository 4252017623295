<template>
    <h3>
        page 1!
    </h3>
</template>
<script>
export default {

}
</script>
<style lang="scss">

</style>